import NoteCard from "notes/components/NoteCard";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import getAllNotes from "collection/graphql/notes/queries/getAllNotes";
import useRestSuspenseQuery from "hooks/useRestSuspenseQuery";
import useViewType from "hooks/useViewType";
import useYearFieldCrops from "modules/fields/hooks/useYearFieldCrops";

import { withSuspenseWrapper } from "components/fl-ui/LoadingWrapper";
import PayGatedScoutingNotes from "components/fl-ui/ScoutingNotes/PayGatedScoutingNotes";
import BlankDetail from "fields/components/BlankDetail";
import ListWrapper from "fields/components/ListWrapper";

const ScoutingNotes = withSuspenseWrapper(({ fieldId, fieldName, showAll, year }) => {
  const isMobile = useViewType() === "mobile";
  const { notes } = useRestSuspenseQuery(getAllNotes).data;
  const { getCropById } = useYearFieldCrops();

  const filteredNotes = useMemo(() => {
    const getNotesYear = ({ cropId, date }) => (cropId ? getCropById(cropId)?.cropYear : +date.substring(0, 4));

    return notes.filter((note) => note.fieldId === fieldId).filter((note) => showAll || getNotesYear(note) === year);
  }, [getCropById, year]);

  return (
    <div>
      {isMobile && !showAll ? (
        <PayGatedScoutingNotes fieldId={fieldId}>
          {(handleNavigate) => (
            <ListWrapper buttonAction={handleNavigate} buttonLabel="Add note" list={filteredNotes}>
              {({ displayed }) => (
                <div className="note-list xs-block-grid-1 md-block-grid-2">
                  {displayed.map((note) => (
                    <NoteCard fieldName={fieldName} key={note.id} note={note} />
                  ))}
                </div>
              )}
            </ListWrapper>
          )}
        </PayGatedScoutingNotes>
      ) : (
        <div className="note-list xs-block-grid-1 md-block-grid-2">
          {filteredNotes.map((note) => (
            <NoteCard fieldName={fieldName} key={note.id} note={note} />
          ))}

          {!!filteredNotes.length && !showAll && (
            <div className="col xs-12">
              <PayGatedScoutingNotes fieldId={fieldId}>
                {(handleNavigate) => (
                  <Link
                    className="btn btn-white btn-small float-left"
                    style={{ marginLeft: "0.5rem", marginTop: "1rem" }}
                    to={`/scouting/add?field_id=${fieldId}`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavigate();
                    }}
                  >
                    + Add note
                  </Link>
                )}
              </PayGatedScoutingNotes>
            </div>
          )}
        </div>
      )}

      {!filteredNotes.length && (
        <PayGatedScoutingNotes fieldId={fieldId}>
          {(handleNavigate) => (
            <BlankDetail onClick={handleNavigate}>
              <p>Add note...</p>
            </BlankDetail>
          )}
        </PayGatedScoutingNotes>
      )}
    </div>
  );
});

ScoutingNotes.propTypes = {
  fieldId: PropTypes.number.isRequired,
  showAll: PropTypes.bool,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ScoutingNotes;
