import { upperFirst } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import ToolTip from "components/fl-ui/Tooltip";
import { IconContainer } from "components/fl-ui/Icons";

const TextHelpTooltip = ({ message, text }) => (
  <span style={{ display: "inline-flex" }}>
    {upperFirst(text)}
    <ToolTip message={message}>
      <IconContainer icon="help" size="regular" />
    </ToolTip>
  </span>
);

TextHelpTooltip.propTypes = {
  message: PropTypes.string,
  text: PropTypes.string,
};

export default TextHelpTooltip;
