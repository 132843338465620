import styled, { css } from "styled-components";

const OverlayContainer = styled.div`
  ${(props) =>
    (props.placement.startsWith("top") || props.placement.startsWith("bottom")) &&
    css`
      padding: 5px 0;
    `}
  ${(props) =>
    (props.placement.startsWith("left") || props.placement.startsWith("right")) &&
    css`
      padding: 0 5px;
    `}
  z-index: 10000;
`;

export default OverlayContainer;
