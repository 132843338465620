import { ApolloProvider } from "@apollo/client";
import { createAuthWorkflowStore } from "app/workflows/sso/SSOAuthStore";
import SSOAuthWorkflow from "app/workflows/sso/SSOAuthWorkflow";
import {
  COLLECT_EMAIL,
  COLLECT_USER_DETAILS,
  CONFIRM_FARM_NAME,
  CONNECT_TO_CONTRACTS,
  CREATE_ENTERPRISE,
  DONE,
  ERROR,
  SELECT_MEMBERSHIP,
  SIGN_UP,
} from "app/workflows/sso/constants";
import React, { useEffect, useMemo, useState } from "react";
import { Navigate } from "react-router-dom";
import SSOErrorView from "sso/components/SSOErrorView";
import SSOConfirmFarmName from "sso/pages/SSOConfirmFarmName";
import SSOConnectToContracts from "sso/pages/SSOConnectToContracts";
import SSOCreateNewEnterprise from "sso/pages/SSOCreateNewEnterprise";
import SSOEnterpriseManagement from "sso/pages/SSOEnterpriseManagement";

import { LEGACY, useAuthStore } from "collection/graphql/auth/hooks/AuthStore";
import { createSSOAuthWorkflowClient } from "collection/graphql/client/ssoAuthWorkflowClient";
import { SSOContext } from "context/SSOContext";
import FinishSSOFlow from "modules/sso/navigation/FinishSSOFlow";
import NavigateToLandingPage from "modules/sso/navigation/NavigateToLandingPage";
import SSOSignUp from "modules/sso/pages/SSOSignUp";
import SSOUserInformationScreen from "modules/sso/pages/SSOUserInformationScreen";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const SSOEntryPoint = () => {
  const { isAuthenticated, strategy } = useAuthStore();

  const [result, setResult] = useState(null);

  const client = useMemo(createSSOAuthWorkflowClient, []);
  const authWorkflowStore = useMemo(() => {
    return createAuthWorkflowStore(useAuthStore, client);
  }, [client]);

  const authWorkflow = useMemo(() => {
    return new SSOAuthWorkflow(authWorkflowStore);
  }, [client, authWorkflowStore]);

  const startWorkflow = async () => {
    const result = await authWorkflow.start();
    setResult(result);
  };

  useEffect(() => {
    if (isAuthenticated) {
      void startWorkflow();
    }
  }, [isAuthenticated]);

  if (strategy === LEGACY) {
    return <NavigateToLandingPage />;
  }

  if (result === COLLECT_EMAIL) {
    return <Navigate replace to="/sign_in" />;
  }

  //TODO:Move onComplete to a context to reduce prop drilling as part of https://bushel.atlassian.net/browse/FARM-9808
  return (
    <ApolloProvider client={client}>
      <SSOContext.Provider value={authWorkflowStore.getState()}>
        <div>
          {!result && <LoadingWrapper isLoading />}
          {result === CONFIRM_FARM_NAME && <SSOConfirmFarmName onComplete={startWorkflow} />}
          {result === SELECT_MEMBERSHIP && (
            <SSOEnterpriseManagement onComplete={startWorkflow} onCreate={() => setResult(CREATE_ENTERPRISE)} />
          )}
          {result === CREATE_ENTERPRISE && <SSOCreateNewEnterprise onComplete={startWorkflow} />}
          {result === SIGN_UP && <SSOSignUp onComplete={startWorkflow} />}
          {result === COLLECT_USER_DETAILS && <SSOUserInformationScreen onComplete={startWorkflow} />}
          {result === CONNECT_TO_CONTRACTS && <SSOConnectToContracts onComplete={startWorkflow} />}
          {result === ERROR && <SSOErrorView />}
          {result === DONE && <FinishSSOFlow />}
        </div>
      </SSOContext.Provider>
    </ApolloProvider>
  );
};

export default SSOEntryPoint;
