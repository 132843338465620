/* eslint-disable react/display-name */
import useSubscriptionPricing from "billing/hooks/useSubscriptionPricing";
import { isNative } from "mobile/mobileManager";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { getCurrentSubscription } from "collection/graphql/subscription";
import useRestSuspenseQuery from "hooks/useRestSuspenseQuery";
import { BILLING_PAGE_ENTER } from "lib/metrics/events";

const withBillingFormState = (WrappedComponent) => () => {
  const { subscription } = useRestSuspenseQuery(getCurrentSubscription).data;

  let defaultBillingInterval = "year";
  if (isNative()) {
    defaultBillingInterval = "month";
  } else if (subscription.currentPlan.isPurchasable && subscription.billingInterval) {
    defaultBillingInterval = subscription.billingInterval;
  }

  const form = useForm({
    defaultValues: {
      agreedToTerms: false,
      billingInterval: defaultBillingInterval,
      coupon: null,
      selectedPlanId: subscription.currentPlan.isPurchasable ? subscription.currentPlan.id : null,
    },
  });

  const [billingInterval, coupon, selectedPlanId] = form.watch(["billingInterval", "coupon", "selectedPlanId"]);

  const { effectiveBillingInterval } = useSubscriptionPricing(selectedPlanId, billingInterval, coupon);
  useEffect(() => {
    if (effectiveBillingInterval !== billingInterval) {
      form.setValue("billingInterval", effectiveBillingInterval);
    }
  }, [billingInterval, effectiveBillingInterval]);

  useEffect(() => {
    BILLING_PAGE_ENTER.track({
      currentPlanId: subscription.currentPlan.id,
    });
  }, [subscription.currentPlan.id]);

  return (
    <FormProvider {...form}>
      <WrappedComponent />
    </FormProvider>
  );
};

export default withBillingFormState;
