"use strict";
//I'm creating an extremely basic version of this file to begin storing all of our media breakpoints as constants
//  We'll continue adding to this as we come across them, but I wanted to leave a couple notes:
//     1) We should move every max-width, min-width, etc. into this file.
//     2) If you (or future me) have better ideas for names for these please change them, I'm very open.
//     3) This src/constants directory might make sense to move more global constants into as we come across them.
//        We have tons of different constants files currently that are nested in directories but are actually pretty global
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrimaryMaxWidth = void 0;
exports.PrimaryMaxWidth = "62.5rem";
