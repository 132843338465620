import { useState } from "react";

export const useVisibility = () => {
  const [isVisible, setIsVisibility] = useState(false);

  return {
    isVisible,
    hide: () => setIsVisibility(false),
    show: () => setIsVisibility(true),
    toggle: () => setIsVisibility((isVisible) => !isVisible),
  };
};

export default useVisibility;
