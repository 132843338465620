import React, { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import SoilLayer from "lib/map/layers/soil";
import useSoilLayers from "lib/map/layers/useSoilLayers";
import { MoreLink } from "modules/fields/components/DetailsWidget";
import { WidgetHeader } from "modules/fields/components/WidgetHeader";

import BasicFieldMap from "components/field/BasicFieldMap";
import { TOO_BIG_ACREAGE } from "components/field/utils";
import { IconContainer } from "components/fl-ui/Icons";
import Loading from "components/ui/loading";

const soilLayer = new SoilLayer();
const getSoilData = (layer) => {
  if (layer) {
    const { features } = layer.geometry;
    for (const feature of Array.from(features)) {
      soilLayer.addFeature(feature);
    }

    return soilLayer.group();
  }

  return [];
};

const Soil = ({ field }) => {
  const fieldId = field.id;
  const navigate = useNavigate();
  const soilMapsFeatureFlag = !useEnterpriseFeature("soil_maps");
  const { loading, soilLayers } = useSoilLayers({ fieldId, skip: soilMapsFeatureFlag });
  const soilData = useMemo(() => getSoilData(soilLayers[0]), [soilLayers]);
  const isPoint = field.geometry.type.toLowerCase() === "point";
  const isTooBig = +field.acreage > TOO_BIG_ACREAGE;
  const hasSoilData = soilData.length > 0;

  return (
    <div className="row-fluid">
      <WidgetHeader className="field-detail-widget-header col xs-12">
        <h2>Soil</h2>
        {!!soilData.length && (
          <MoreLink to={`/map/${fieldId}/layers?type=soil.ssurgo`}>
            Full soil map
            <IconContainer icon="chevronRight" />
          </MoreLink>
        )}
      </WidgetHeader>

      <Loading loading={loading}>
        <span>
          {isPoint && (
            <div className="blank-slate">
              <p>
                <Link to={`/fields/${fieldId}/edit`} style={{ "font-weight": "bold" }}>
                  Draw the shape of this field
                </Link>
                {` to see soil texture and slope.`}
              </p>
            </div>
          )}

          {!isPoint && !hasSoilData && (
            <div className="blank-slate">
              <span className="font-size-l">
                {"Soil data is unavailable for "}
                {isTooBig ? `fields larger than ${TOO_BIG_ACREAGE} acres.` : "this location."}
              </span>
            </div>
          )}

          {hasSoilData && (
            <div>
              <div>
                <div className="col xs-12 md-3">
                  <BasicFieldMap
                    geometry={field.geometry}
                    layer={soilLayer}
                    onClick={() => navigate(`/map/${fieldId}/layers?type=soil.ssurgo`)}
                    style={{ height: "14.5rem" }}
                  />
                </div>
                <div className="col xs-hide sm-hide md-1">{" "}</div>
              </div>

              <div className="col xs-12 md-8">
                <table>
                  <tbody>
                    {Array.from(soilData).map(({ acreage, color, coverage, key, name }) => (
                      <tr className="soil-legend" key={key}>
                        <td>
                          <div className="soil-legend-swatch" style={{ background: color }} />
                          {name}
                        </td>
                        <td>
                          {acreage}
                          {" ac"}
                        </td>
                        <td>{coverage.toFixed(1)}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </span>
      </Loading>
    </div>
  );
};

export default Soil;
