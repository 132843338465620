/* eslint-disable react/display-name */
import { useViewportSpy } from "beautiful-react-hooks";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FieldMapButton } from "modules/fields/components/FieldMapButton";

import BasicFieldMap from "components/field/BasicFieldMap";

export const withLazyRender = (Component) => (props) => {
  const { className, height, width } = props;
  const ref = useRef();
  const isCurrentlyVisible = useViewportSpy(ref, { rootMargin: "10px" });
  const [hasBeenVisible, setHasBeenVisible] = useState(false);
  useEffect(() => {
    if (!hasBeenVisible && isCurrentlyVisible) {
      setHasBeenVisible(true);
    }
  }, [isCurrentlyVisible]);

  return (
    <span ref={ref}>
      {hasBeenVisible ? (
        <Component {...props} />
      ) : (
        <FieldMapButton as="span" className={className} height={height} width={width} />
      )}
    </span>
  );
};

const FieldMapImage = ({ className, field, height, layer, pattern, width }) => {
  const navigate = useNavigate();
  const handleDrawShape = (event) => {
    event.preventDefault();
    navigate(`/fields/${field.id}/edit`);
  };

  if (!field.geometry) {
    return <FieldMapButton className={className} height={height} width={width} />;
  }

  return field.geometry?.type?.toLowerCase() === "point" ? (
    <FieldMapButton className={className} height={height} width={width} onClick={handleDrawShape}>
      Draw shape
    </FieldMapButton>
  ) : (
    <BasicFieldMap
      className={className}
      computeLayer={(isVisible, layer) => (isVisible ? layer : null)}
      geometry={field.geometry}
      layer={layer}
      pattern={pattern}
      style={{ height, width }}
    />
  );
};

FieldMapImage.propTypes = {
  className: PropTypes.string,
  field: PropTypes.shape({
    geometry: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  height: PropTypes.number,
  layer: PropTypes.object.isRequired,
  pattern: PropTypes.object,
  width: PropTypes.number,
};

FieldMapImage.defaultProps = {
  height: 48,
  width: 48,
};

export default FieldMapImage;
