import { isNative } from "mobile/mobileManager";
import moment from "moment";

import { ADDONS, BUSINESS_TRIAL, CUSTOM, LEGACY, PLANS, STANDARD } from "model/Subscription/constants";

const SubscriptionTypePolicy = {
  fields: {
    addons: {
      read(__, { readField }) {
        return readField("products").filter((__, i) => {
          const id = readField("id", readField(i + "", readField("products")));
          return ADDONS.includes(id);
        });
      },
    },

    canManageSubscription: {
      read(__, { readField }) {
        if (!readField("selfService")) {
          return false; // backend has prevented you from managing subscriptions at all
        }
        if (readField("hasNativeSubscription")) {
          return false; // all native subscriptions handled through app/play stores exclusively
        }
        if (isNative() && readField("sources").length > 0) {
          return readField("id", readField("currentPlan")) === BUSINESS_TRIAL; // mobile apps can only manage/upgrade from a business trial sub
        }

        return true;
      },
    },

    currentPlan: {
      read(__, { readField }) {
        const defaultPlans = readField("products").filter((__, i) => {
          const id = readField("id", readField(i + "", readField("products")));
          return PLANS.includes(id);
        });

        return defaultPlans[0];
      },
    },

    daysRemaining: {
      read(__, { readField }) {
        const periodEnd = readField("periodEnd", readField("currentPlan"));
        if (periodEnd) {
          const daysRemaining = moment(periodEnd).diff(new Date(), "days");
          return Math.max(0, daysRemaining);
        }
      },
    },

    hasNativeSubscription: {
      read(__, { readField }) {
        const sources = readField("sources");
        return sources.includes("android") || sources.includes("ios");
      },
    },

    isCancelable: {
      read(__, { readField }) {
        return (
          !readField("cancelAtPeriodEnd") &&
          readField("canManageSubscription") &&
          readField("isSubscribed") &&
          readField("id", readField("currentPlan")) !== BUSINESS_TRIAL &&
          !isNative()
        );
      },
    },

    isCustomPlan: {
      read(__, { readField }) {
        return readField("id", readField("currentPlan")) === CUSTOM;
      },
    },

    isSubscribed: {
      read(__, { readField }) {
        return readField("isSubscribedToPlan") || readField("addons").length > 0;
      },
    },

    isSubscribedToPlan: {
      read(__, { readField }) {
        return ![LEGACY, STANDARD].includes(readField("id", readField("currentPlan")));
      },
    },

    isTrialing: {
      read(__, { readField }) {
        return !readField("cancelAtPeriodEnd") && /^trialing$/i.test(readField("status"));
      },
    },

    sources: {
      read(__, { readField }) {
        const products = readField("products");
        return products.reduce((result, __, i) => {
          const product = readField(i + "", products);
          const source = readField("source", product);
          if (source) {
            result.push(source);
          }

          return result;
        }, []);
      },
    },
  },
};

export default SubscriptionTypePolicy;
