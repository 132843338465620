import { css } from "aphrodite/no-important";
import cx from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

import { ColorSpinner, Icon } from "components/fl-ui/Icons";
import styles, { getBtnClasses, getSpinnerColor, iconSizes, SIZE } from "components/fl-ui/buttonStyles";

const Button = (props) => {
  const { children, color, hollow, icon, iconClassName, iconRight, lightWeight, link, loading, size, strategy } = props;
  const classNames = {
    btn: true,
    // colors
    "btn-primary": color === "primary",
    "btn-red": color === "danger",
    "btn-transparent": color === "white",
    "btn-secondary": color === "secondary",
    "btn-light-weight": lightWeight,
    "btn-hollow": hollow,
    // sizes
    "btn-xl": size === "xl",
    "btn-lg": size === "lg",
    "btn-md": size === "md",
    "btn-sm": size === "sm",
    "btn-xs": size === "xs",
  };
  const buttonProps = {
    ..._.omit(props, [
      "children",
      "color",
      "display",
      "hollow",
      "icon",
      "iconClassName",
      "iconRight",
      "lightWeight",
      "link",
      "loading",
      "replace",
      "size",
    ]),
    className: strategy === "new" ? cx(classNames) : getBtnClasses(props),
  };

  return (
    <button {...buttonProps}>
      {loading && (
        <ColorSpinner
          className={css(styles.spinner)}
          color={getSpinnerColor(color, link, hollow)}
          size={SIZE[size].fontSize}
        />
      )}
      {icon && (
        <div
          className={css(
            styles.buttonIcon,
            size && iconSizes[size],
            iconRight && styles.buttonIcon_right,
            !children && styles.buttonIcon_noChildren
          )}
        >
          <Icon
            className={`${css(["xs", "sm", "mn"].includes(size) && styles.buttonIcon_bold)} ${iconClassName}`}
            icon={icon}
          />
        </div>
      )}
      {children}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(["default", "primary", "secondary", "danger", "white"]),
  children: PropTypes.any,
  disabled: PropTypes.bool,
  display: PropTypes.string,
  form: PropTypes.string,
  hollow: PropTypes.bool,
  lightWeight: PropTypes.bool,
  link: PropTypes.bool,
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  iconRight: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  raised: PropTypes.bool,
  replace: PropTypes.bool,
  size: PropTypes.oneOf(["xl", "lg", "md", "mn", "sm", "xs"]),
  strategy: PropTypes.string,
  type: PropTypes.oneOf(["button", "reset", "submit"]),
};

Button.defaultProps = {
  color: "default",
  replace: false,
  size: "md",
  type: "button",
};

export default Button;
