import { BUSINESS_TRIAL, LEGACY, STANDARD } from "model/Subscription/constants";

const SubscriptionPackageTypePolicy = {
  fields: {
    isPurchasable: {
      read(__, { readField }) {
        return ![BUSINESS_TRIAL, LEGACY, STANDARD].includes(readField("id"));
      },
    },

    year: {
      read(__, { readField }) {
        return +readField("year", readField("meta")) || null;
      },
    },
  },
};

export default SubscriptionPackageTypePolicy;
