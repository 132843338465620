import { useQuery } from "@apollo/client";
import { css } from "aphrodite/no-important";
import Toggle from "marketing/components/ui-elements/Toggle";
import ContractsTableHeader from "marketing/tables/ContractsTableHeader";
import tableStyleSheet from "marketing/tables/styles";
import useContractTables, { ContractTableContextProvider } from "marketing/tables/useContractTables";
import dollarFormat from "marketing/utils/dollarFormat";
import formatCommas from "marketing/utils/formatCommas";
import formatDate from "marketing/utils/formatDate";
import PropTypes from "prop-types";
import React, { useMemo, useReducer } from "react";

import useEditOptionsContract from "collection/graphql/contracts/hooks/useEditOptionsContract";
import { OPTIONS_CONTRACTS } from "collection/graphql/marketing";

import ContractStatusCopy from "components/copy/ContractStatusCopy";
import ContractSubtypeCopy from "components/copy/ContractSubtypeCopy";
import { Button } from "components/fl-ui";
import DataTable from "components/fl-ui/DataTable";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { Col, Row } from "components/fl-ui/Table";

/*
 * Default sort order (per https://bit.ly/2ReVWLv)
 *
 * primary: by expiration date ascending
 * secondary: by reference contract date ascending
 * tertiary: by optionsContractType (not requested by product, but there will be a lot of overlap in the first two columns)
 */
const defaultContractSort = (contracts = []) => {
  return contracts.slice(0).sort((a, b) => {
    if (a.expirationDate === b.expirationDate) {
      if (a.contractDate === b.contractDate) {
        return a.optionsContractType < b.optionsContractType ? -1 : 1;
      }

      return a.contractDate < b.contractDate ? -1 : 1;
    }

    return a.expirationDate < b.expirationDate ? -1 : 1;
  });
};

const OptionsContractsTable = ({ commodity, contracts, loading, onEnabledToggle, summary }) => {
  const { filters, onAdd, onEdit } = useContractTables();
  const { contractStatus } = filters;

  const handleIncludeChange = (event, contract) => onEnabledToggle(contract, event.target.checked);

  const columns = [
    { label: "Include" },
    { label: "Type", sortBy: "optionsContractType" },
    { label: "Status", sortBy: "contractStatus" },
    { label: "Booked", sortBy: "contractDate" },
    { label: "Ref. contract", sortBy: "underlying.shortName" },
    {
      label: "Qty",
      sortBy: "quantity",
      type: "number",
    },
    { label: "Strike price", sortBy: "strikePrice", type: "number" },
    { label: "Fees", sortBy: "premium", type: "number" },
  ];

  if (contractStatus === "OPEN") {
    columns.push(
      { label: "Last Price", sortBy: "premium", type: "number" },
      { label: "Gain/Loss", sortBy: "mtm", type: "number" }
    );
  } else {
    columns.push({
      label: "Revenue Impact",
      sortBy: "revenueImpact",
      type: "number",
    });
  }

  columns.push({
    label: "Expiration",
    sortBy: "expirationDate",
  });

  if (loading) {
    return <LoadingWrapper />;
  } else if (contracts.length === 0) {
    return (
      <div className="panel text-centered">
        <b>
          <div className="empty-panel-content">
            <span>Enter your options contracts here</span>
          </div>
          <Button id="new-other-contract-button" onClick={onAdd}>
            Add new contracts
          </Button>
        </b>
      </div>
    );
  }

  return (
    <DataTable
      columns={columns}
      footer={
        <Row>
          <Col className={css(tableStyleSheet.footerCol)} width={5} />
          <Col className={css(tableStyleSheet.footerCol)} type="number">
            {`Total `}
            <b id="options-summary-total-quantity">{formatCommas(summary.totalQuantity)}</b>
          </Col>
          <Col className={css(tableStyleSheet.footerCol)} width={3} />
          {contractStatus === "OPEN" && (
            <Col className={css(tableStyleSheet.footerCol)} type="number">
              <b>{dollarFormat(summary.totalGainLoss)}</b>
            </Col>
          )}
          <Col className={css(tableStyleSheet.footerCol)} />
        </Row>
      }
      values={contracts}
    >
      {(c) => (
        <Row
          className="smoke-97-hover"
          id={`options-contract-${c.id}-row`}
          key={c.id}
          onClick={() => onEdit(c)}
          style={{
            color: !c.enabled ? "hsla(220, 10%, 20%, .20)" : "",
          }}
        >
          <Col id="include-toggle">
            <Toggle defaultChecked={c.enabled} icons={false} onChange={(e) => handleIncludeChange(e, c)} />
          </Col>
          <Col id="contract-type">
            <span className={!c.enabled ? "light-blue-link" : "blue-link"}>
              <ContractSubtypeCopy subtype={c.contractType} />
            </span>
          </Col>
          <Col id="contract-status">
            <ContractStatusCopy status={c.contractStatus} />
          </Col>
          <Col id="date-booked">{formatDate(new Date(c.contractDate))}</Col>
          <Col id="ref-contract">{c.underlying?.shortName ?? "--"}</Col>
          <Col id="contract-quantity" type="number">
            {formatCommas(c.quantity)}
          </Col>
          <Col id="contract-strike-price" type="number">
            {dollarFormat(c.strikePrice, { returnZero: true })}
          </Col>
          <Col id="contract-premium" type="number">
            {dollarFormat(c.premium, { returnZero: true })}
          </Col>

          {contractStatus === "OPEN" && (
            <>
              <Col id="contract-last-price" type="number">
                {dollarFormat(c.currentMarketPremium, { returnZero: true })}
              </Col>
              <Col id="contract-mtm" type="number">
                {dollarFormat(c.mtm, { returnZero: true })}
              </Col>
            </>
          )}

          {contractStatus === "CLOSED" && (
            <Col id="contract-revenue" type="number">
              {dollarFormat(c.revenueImpact, { returnZero: true })}
            </Col>
          )}
          <Col id="options-contract-delivery-date">{formatDate(new Date(c.expirationDate))}</Col>
        </Row>
      )}
    </DataTable>
  );
};

const optionsContractsReducer = (state, updates) => ({
  ...state,
  ...updates,
});

const OptionsContracts = ({ commodity, marketedCropId, productionStats, year }) => {
  const [contractFilters, dispatch] = useReducer(optionsContractsReducer, null, () => ({
    contractSource: "OPTIONS",
    contractStatus: "OPEN",
  }));

  const { data } = useQuery(OPTIONS_CONTRACTS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      ...contractFilters,
      marketedCropId,
    },
  });
  const { contracts, summary } = data?.contractData ?? {};
  const sortedContracts = useMemo(() => defaultContractSort(contracts), [contracts]);

  const saveContract = useEditOptionsContract();
  const onEnabledToggle = async (contract, enabled) =>
    saveContract({
      ...contract,
      enabled,
    });

  return (
    <ContractTableContextProvider
      contractType="OptionsContract"
      filters={contractFilters}
      marketedCropId={marketedCropId}
      updateFilters={dispatch}
      year={year}
    >
      <ContractsTableHeader productionStats={productionStats} title="Options Contracts" />

      <OptionsContractsTable
        commodity={commodity}
        contracts={sortedContracts}
        loading={!contracts}
        onEnabledToggle={onEnabledToggle}
        summary={summary}
      />
    </ContractTableContextProvider>
  );
};

OptionsContracts.propTypes = {
  commodity: PropTypes.object.isRequired,
  marketedCropId: PropTypes.number.isRequired,
  productionStats: PropTypes.shape({
    percentage: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  }),
  year: PropTypes.number.isRequired,
};

export { defaultContractSort };
export default OptionsContracts;
