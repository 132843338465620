import PropTypes from "prop-types";
import React, { useState } from "react";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";

import FileAttachmentsBlankStateModal from "components/advertisements/modals/FileAttachmentsBlankStateModal";
import FileAttachments from "components/fl-ui/Attachments/FileAttachmentsAdapter";

const PayGatedFileAttachments = ({ children: DisplayedComponent, ...props }) => {
  const [payGate, setPayGate] = useState(false);
  const hasAttachments = useEnterpriseFeature("file_storage");
  const togglePayGate = () => setPayGate((payGateStatus) => !payGateStatus);

  return (
    <FileAttachments {...props}>
      {({ onClick }) => (
        <>
          <DisplayedComponent onClick={hasAttachments ? onClick : togglePayGate} />
          {payGate && <FileAttachmentsBlankStateModal onClose={togglePayGate} />}
        </>
      )}
    </FileAttachments>
  );
};

PayGatedFileAttachments.propTypes = {
  children: PropTypes.any.isRequired,
};

export default PayGatedFileAttachments;
