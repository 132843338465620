import { size } from "lodash";
import { array, number, object, string } from "yup";

const NoteSchema = object({
  created: string().optional(),
  cropId: number().nullable(),
  date: string().required().label("Date"),
  enterpriseId: number().required().label("Enterprise id"),
  fieldId: number().label("Field").positive("fieldId must be a positive number").nullable(),
  geometry: object()
    .label("Location")
    .shape({
      coordinates: array().of(number()).required(),
      type: string().oneOf(["Point"]).required(),
    })
    .nullable()
    .optional(),
  id: number().optional(),
  images: array()
    .of(
      object({
        filepickerId: string().required(),
        name: string().optional(),
        s3Id: string().required(),
        size: number().optional(),
      })
    )
    .default([])
    .nullable(),
  notes: string()
    .label("Notes")
    .when(["geometry", "images"], {
      is: (geometry, images) => !!geometry || size(images) > 0,
      then: (schema) => schema.optional().nullable(),
      otherwise: (schema) => schema.required("Please add note text unless a location is set or an image is attached."),
    }),
  people: array().label("People").of(number()).default([]).required(),
  updated: string().optional(),
});

export default NoteSchema;
