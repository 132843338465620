import { browserOpenUrl, isNative } from "mobile/mobileManager";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useFullScreenHandle } from "react-full-screen";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";

import useFieldAttachments from "collection/graphql/fields/hooks/useFieldAttachments";

import AttachmentList from "components/field/AttachmentList";
import { getImageUrl } from "components/field/utils";
import { Button } from "components/fl-ui";
import PayGatedFileAttachments from "components/fl-ui/Attachments/PayGatedFileAttachments";
import FullscreenContainer from "components/fl-ui/Layout/FullscreenContainer";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { UIColors } from "components/fl-ui/colors";
import { fileClient } from "components/fl-ui/common";
import { Borders, Spacing } from "components/fl-ui/constants";
import BlankDetail from "fields/components/BlankDetail";

const AttachmentContainer = styled(Row)`
  border: ${Borders.regular};
  margin: 0;
  padding: ${Spacing.large};

  @media only screen and (max-width: 62.5rem) {
    border: none;
    padding: 0;
  }
`;

const AttachmentPreview = styled.img`
  height: 22.5rem;
  object-fit: contain;
  padding: ${Spacing.xsmall};
  width: 100%;
`;

const LightHeader = styled.h5`
  border-bottom: ${Borders.regular};
  color: ${UIColors.light};
  margin: 0;
  padding: ${Spacing.xsmall} 0;
  text-transform: uppercase;

  @media only screen and (max-width: 62.5rem) {
    border: none;
    padding: ${Spacing.xxsmall} 0;
  }
`;

const RightAlignedContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`;

const AttachmentDisplay = ({ fileList, isUpdating, updateAttachments }) => {
  const [selectedFile, setSelectedFile] = useState(fileList[0]);
  const [hasPreview, setHasPreview] = useState(true);
  const handle = useFullScreenHandle();
  const isWeb = !isNative();

  useEffect(() => {
    if (!fileList.find(({ id }) => id === selectedFile.id)) {
      setSelectedFile(fileList[0]);
    }
  }, [selectedFile.id, fileList]);

  const handlePreview = () => {
    if (hasPreview) {
      if (isWeb) {
        handle.enter();
      } else {
        browserOpenUrl(fileClient.getPreviewUrl(selectedFile.filepickerId));
      }
    }
  };

  const handleError = ({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src = "/images/app_icons/bushel-farm-icon-alt.svg";
    setHasPreview(false);
  };

  const handleSelected = (file) => {
    setHasPreview(true);
    setSelectedFile(file);
  };

  return (
    <AttachmentContainer>
      {isWeb && (
        <Col xs={false} md={6}>
          <AttachmentPreview
            onClick={handlePreview}
            onError={handleError}
            src={getImageUrl(selectedFile.filepickerId, 360)}
          />
        </Col>
      )}

      <Col xs={12} md={isWeb ? 6 : 12}>
        <LightHeader>Recent Uploads</LightHeader>
        <AttachmentList
          fileList={fileList.slice(0, 4)}
          isUpdating={isUpdating}
          selectedFile={selectedFile}
          setSelectedFile={handleSelected}
          showPreview={handlePreview}
          updateAttachments={updateAttachments}
        />

        <PayGatedFileAttachments onChange={updateAttachments}>
          {({ onClick }) => (
            <RightAlignedContainer>
              <Button color="primary" lightWeight link onClick={onClick}>
                Add attachment
              </Button>
            </RightAlignedContainer>
          )}
        </PayGatedFileAttachments>
      </Col>

      <FullscreenContainer handle={handle}>
        <iframe src={fileClient.getPreviewUrl(selectedFile.filepickerId)} style={{ height: "100vh", width: "100%" }} />
      </FullscreenContainer>
    </AttachmentContainer>
  );
};

const Attachments = ({ fieldId }) => {
  const { attachments, isUpdating, loading, updateAttachments } = useFieldAttachments(fieldId);

  return (
    <LoadingWrapper isLoading={loading}>
      {attachments?.length ? (
        <AttachmentDisplay fileList={attachments} isUpdating={isUpdating} updateAttachments={updateAttachments} />
      ) : (
        <PayGatedFileAttachments onChange={updateAttachments}>
          {({ onClick }) => (
            <BlankDetail onClick={onClick}>
              <p>No attachments for this field</p>
              <span>Add attachment</span>
            </BlankDetail>
          )}
        </PayGatedFileAttachments>
      )}
    </LoadingWrapper>
  );
};

Attachments.propTypes = {
  fieldId: PropTypes.number.isRequired,
};

export default Attachments;
