import { useQuery } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";

import { useAuth } from "collection/graphql/auth";
import { GET_CURRENT_USER } from "collection/graphql/auth/queries";
import { getCurrentUserMembership } from "collection/graphql/enterprise/queries";
import { updatePerson } from "collection/graphql/people/mutations";
import useRestMutation from "hooks/useRestMutation";
import App from "layout/app";
import DeactivateUserButton from "modules/settings/components/DeactivateUserButton";

import { Button } from "components/fl-ui";
import { ControlledFormGroup, Form, Input } from "components/fl-ui/Form/index";
import { CloseX } from "components/fl-ui/Icons";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
import { GreyColors } from "components/fl-ui/colors";

const firstName = yup
  .string()
  .label("First name")
  .required()
  .max(100, "First Name cannot be longer than 100 characters");
const lastName = yup.string().label("Last name").required().max(100, "Last Name cannot be longer than 100 characters");

const MyProfileFormSchema = yup.object().shape({
  firstName,
  lastName,
});

//Unfortunately, this file cannot be TypeScript because of the way <FormProvider> is being used
//Would be really nice to update this at some point in the near future.
const MyProfileModal = styled(({ className, close }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { currentUser } = useAuth(); // Assuming `currentUser` is typed from `useAuth`
  const { data: userMembership } = useQuery(getCurrentUserMembership);
  const role = userMembership?.currentMembership?.role?.name;

  const [savePerson] = useRestMutation(updatePerson, {
    refetchQueries: [GET_CURRENT_USER],
  });

  const methods = useForm({
    defaultValues: MyProfileFormSchema.cast(
      {
        firstName: currentUser?.firstName || "",
        lastName: currentUser?.lastName || "",
      },
      { assert: false, stripUnknown: true }
    ),
    mode: "onTouched",
    resolver: yupResolver(MyProfileFormSchema),
  });

  const handleSubmit = async ({ firstName, lastName }) => {
    setIsSubmitting(true);

    try {
      await savePerson({
        variables: {
          input: {
            ...currentUser,
            firstName,
            lastName,
            role,
          },
        },
      });
      App.notify("Changes saved.");
    } catch (error) {
      App.notify("An error occurred");
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal id="account-deactivation-modal" width={625}>
      <ModalHeader>
        <ModalTitle>My Profile</ModalTitle>
        <CloseX onClick={close} />
      </ModalHeader>

      <FormProvider {...methods} schema={MyProfileFormSchema}>
        <div className={className}>
          <p className="top-text">
            This will update your account information across all Farms you have access to and will be visible to other
            users.
          </p>
          <Form onSubmit={methods.handleSubmit(handleSubmit)}>
            <ModalBody>
              <ControlledFormGroup
                name="firstName"
                render={({ field }) => <Input {...field} placeholder="First Name" />}
              />

              <ControlledFormGroup
                name="lastName"
                render={({ field }) => <Input {...field} placeholder="Last Name" />}
              />
            </ModalBody>

            <ModalFooter>
              <div>
                <DeactivateUserButton />

                <Link
                  to={`${process.env.KEYCLOAK_AUTH_ISSUER}/account`}
                  style={{ marginRight: "12px" }}
                  target="_blank"
                >
                  <Button color="primary" hollow>
                    Manage Bushel Account
                  </Button>
                </Link>
              </div>
              <br />
              <br />
              <Button onClick={close}>Cancel</Button>
              {"  "}
              <Button color="primary" disabled={isSubmitting} type="submit">
                Save
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </FormProvider>
    </Modal>
  );
})`
  .top-text {
    padding: 20px 5% 0 5%;
    color: ${GreyColors.smoke70};
  }
`;

export default MyProfileModal;
