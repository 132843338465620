import NotePrimaryAttributes from "notes/components/NotePrimaryAttributes";
import useIconPath from "notes/hooks/useIconPath";
import PropTypes from "prop-types";
import React from "react";
import BodyClassName from "react-body-classname";

import FileAttachmentsAdapter from "components/fl-ui/Attachments/FileAttachmentsAdapter";
import { Container, Header } from "components/fl-ui/Layout";
import StickyControls from "components/fl-ui/StickyControls";

const NoteDetails = ({ data, errors, isNew, onCancel, onChange, onDelete, onEditLocation, onSave }) => {
  const iconPath = useIconPath(data);

  const handleChange = (newData) => {
    onChange({
      ...data,
      ...newData,
    });
  };

  const handleDelete = (event) => {
    event.preventDefault();
    onDelete();
  };

  const handleEditLocation = (event) => {
    if (!event.isDefaultPrevented()) {
      onEditLocation();
    }
  };

  const handleLocationRemoval = (event) => {
    event.preventDefault();
    handleChange({
      ...data,
      geometry: null,
    });
  };

  const handleTextChange = (event) => {
    return handleChange({
      notes: event.target.value,
    });
  };

  return (
    <BodyClassName className="hideNavMenuMobile">
      <Container>
        <Header noBorder />

        <div className="analytics-page container-fluid activity-logger">
          <div className="narrow-page-wrapper form-horizontal">
            <div className="maps-image-container" style={{ cursor: "pointer" }} onClick={handleEditLocation}>
              {data.geometry ? (
                <div className="note-map-image">
                  <div className="note-icon" />
                  <div className="map" style={{ backgroundImage: `url(${iconPath})` }} />
                  <div onClick={handleLocationRemoval} className="close-x close-x-overlay">
                    ×
                  </div>
                </div>
              ) : (
                <div className="add-location-placeholder text-centered">
                  <p>Add Location...</p>
                </div>
              )}
            </div>

            <div className="wide-attribute" style={{ paddingBottom: "10px" }}>
              <textarea
                placeholder="Write note here..."
                className="note-textarea"
                onChange={handleTextChange}
                value={data.notes}
              />
              <span className="js-help-inline help-inline">{errors.notes}</span>
            </div>

            <NotePrimaryAttributes data={data} handleChange={handleChange} errors={errors}>
              <FileAttachmentsAdapter files={data.images} onChange={(images) => handleChange({ images })} carousel />
            </NotePrimaryAttributes>

            <StickyControls contentClass="narrow-page-wrapper" style={{ zIndex: 1 }}>
              <div className="control-buttons">
                <div className="activity-control-buttons">
                  <div className="btn btn-inline" onClick={() => onCancel()}>
                    Cancel
                  </div>
                  <div className="btn btn-primary btn-inline" onClick={onSave}>
                    Save
                  </div>
                </div>

                {!isNew && (
                  <div className="centered-link">
                    <a onClick={handleDelete}>Delete note</a>
                  </div>
                )}
              </div>
            </StickyControls>
          </div>
        </div>
      </Container>
    </BodyClassName>
  );
};

NoteDetails.propTypes = {
  data: PropTypes.object.isRequired,
  errors: PropTypes.object,
  isNew: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEditLocation: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default NoteDetails;
