import { useQuery } from "@apollo/client";
import AARListing from "activity/components/AARListing";
import ActivityAttachments from "activity/components/ActivityAttachments";
import AttributeListing from "activity/components/AttributeListing";
import InputsListing from "activity/components/InputsListing";
import WeatherSummary from "activity/components/WeatherSummary";
import WriteActivityWorkflow from "activity/components/WriteActivityWorkflow";
import { activityDeletionPrompt } from "activity/utils";
import primeActivityCache from "activity/utils/primeActivityCache";
import { css } from "aphrodite";
import useDocumentTitle from "app/hooks/useDocumentTitle";
import _ from "lodash";
import dollarFormat from "marketing/utils/dollarFormat";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";

import useActivityDelete from "collection/graphql/activities/hooks/useActivityDelete";
import { GET_ACTIVITY } from "collection/graphql/activities/queries";
import useConnectivityChange from "hooks/useConnectivityChange";
import useCurrentCropYear from "hooks/useCurrentCropYear";
import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";
import usePermissions from "hooks/usePermissions";
import App from "layout/app";
import styles, { Definition, Label, SectionHeader, StyledGrid } from "modules/activity/common/styles";

import ActivitiesBlankStatePage from "components/advertisements/pages/ActivitiesBlankStatePage";
import { Button } from "components/fl-ui";
import { Container, Stack } from "components/fl-ui/Layout";
import Header from "components/fl-ui/Layout/Header";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import RestrictedAccessBlankslate from "components/fl-ui/RestrictedAccessBlankslate";

const ActivityDetail = ({ action }) => {
  const activityId = +useParams().id;
  const { data, loading, refetch } = useQuery(GET_ACTIVITY, { variables: { id: activityId } });
  const showModal = action === "edit";
  const canReadActivityTypeCosts = usePermissions().canRead("activity_type_costs");
  const isOnline = useConnectivityChange();
  const activity = data?.activity;
  const deleteActivity = useActivityDelete();
  const cropYear = useCurrentCropYear()[0];
  const navigate = useNavigate();
  useDocumentTitle("Activity detail");

  const { loadingAccess, showPaygate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: "activities",
    permissionName: "activities",
  });

  useEffect(() => {
    primeActivityCache({ cropYear });
  }, []);

  const edit = () => navigate(`/activity/${activity.id}/edit`, { replace: true });
  const view = () => navigate(`/activity/${activity.id}`, { replace: true });

  if (loading && !activity) {
    return null;
  }

  if (!activity) {
    App.notify("The requested activity does not exist.");
    navigate("/activity", { replace: true });
    return null;
  }

  const date = moment(activity.date).format("MMMM DD, YYYY");
  const dateWithinLimit = new Date(activity.date) > new Date("2021-08-01");

  const getActivityCategory = () => {
    const { type } = activity;
    return type ? _.upperFirst(type.category.toLowerCase()) : "--";
  };

  const getStatusColor = () => {
    if (activity.isDone) {
      return styles.greenText;
    } else if (activity.isOverdue || activity.isCancelled) {
      return styles.redText;
    }
  };

  const title = `${_.capitalize(activity.type ? activity.type.name : "Unknown")} at ${activity.field.name}`;

  const onDeleteActivity = () => {
    activityDeletionPrompt().then(
      async () => {
        await deleteActivity(activity.id);
        redirect("/activity");
      },
      () => null
    );
  };

  return (
    <Container>
      <LoadingWrapper isLoading={loadingAccess}>
        <Header title={title}>
          <Button color="secondary" disabled={!isOnline} onClick={() => edit()}>
            Edit
          </Button>
        </Header>

        {showPaygate && <ActivitiesBlankStatePage />}
        {showRestricted && <RestrictedAccessBlankslate />}
        {showContent && (
          <Row style={{ fontSize: "14px" }}>
            <Col xs={12} md={8}>
              <SectionHeader label="Activity Details" />

              <Stack>
                <StyledGrid>
                  <Definition inline term={activity.isPlanned ? "Target date" : "Completed date"}>
                    {date}
                  </Definition>

                  <Definition inline term="Field">
                    <Link className="link" data-cy="activityLinkToField" to={`/fields/${activity.field.id}`}>
                      {_.upperFirst(activity.field.name)}
                    </Link>
                  </Definition>

                  <Definition inline term="Type">
                    {_.upperFirst(activity.type ? activity.type.name : "Unknown")}
                  </Definition>
                  <Definition inline term="Category">
                    {getActivityCategory()}
                  </Definition>
                  <Definition inline term="Crop">
                    {_.upperFirst(activity.crop.commodity.name)}
                  </Definition>
                  <Definition inline term="Activity area">
                    {activity.acreage.toFixed(2)} ac
                  </Definition>
                  {canReadActivityTypeCosts && (
                    <Definition inline term="Operational cost">
                      {dollarFormat(activity.operationalCostPerAcre)} / ac
                    </Definition>
                  )}
                  {canReadActivityTypeCosts && (
                    <Definition inline term="Total cost">
                      {dollarFormat(activity.totalCostPerAcre)} / ac
                    </Definition>
                  )}

                  <Definition inline term="Field group">
                    {_.upperFirst(activity.field.group ? activity.field.group.name : "None")}
                  </Definition>

                  <Definition inline term="Status">
                    <span className={css(getStatusColor())}>{activity.statusText}</span>
                  </Definition>

                  <Definition term="Equipment">
                    <AttributeListing
                      attribute={[...activity.tractors, ...activity.implements]}
                      emptyMessage="No equipment for this activity."
                    />
                  </Definition>

                  <Definition term={activity.isPlanned ? "Assigned to" : "Completed by"}>
                    <AttributeListing
                      attribute={activity.people}
                      emptyMessage="No people are assigned to this activity."
                    />
                  </Definition>
                </StyledGrid>

                {activity.inputs.length > 0 || activity.irrigation ? (
                  <InputsListing activity={activity} onProductAdd={() => refetch()} />
                ) : null}

                {activity.isAAR &&
                  activity.recordings?.map((recording) => <AARListing key={recording.id} recording={recording} />)}
              </Stack>
            </Col>

            <Col xs={12} md={4}>
              <div style={{ marginBottom: "15px" }}>
                <SectionHeader borderless label="Attachments" />
                <ActivityAttachments activity={activity} />
              </div>

              <div style={{ marginBottom: "15px" }}>
                <Label>Notes</Label>
                <div>{activity.notes || "--"}</div>
              </div>

              {dateWithinLimit && (
                <div>
                  <Label>{`Weather for ${activity.date}`}</Label>
                  <WeatherSummary field={activity.field} startDateTime={activity.date + `T00:00:00Z`} />
                </div>
              )}
            </Col>
          </Row>
        )}
      </LoadingWrapper>

      {showModal && (
        <WriteActivityWorkflow
          activity={activity}
          onAbort={() => view()}
          onCompletion={() => view()}
          onDelete={onDeleteActivity}
        />
      )}
    </Container>
  );
};

ActivityDetail.propTypes = {
  action: PropTypes.oneOf(["edit", "view"]).isRequired,
};

export default ActivityDetail;
