import { useQuery } from "@apollo/client";
import ActivityInputChooser from "activity/components/choosers/ActivityInputChooser";
import EquipmentChooser from "activity/components/choosers/EquipmentChooser";
import chooserStyles from "activity/components/choosers/FieldCropChooser/chooserStyles";
import { getCategoryOptions } from "activity/utils";
import { css } from "aphrodite";
import _ from "lodash";
import { isNative } from "mobile/mobileManager";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import ReactSelect from "react-select";
import { Col, Row } from "react-styled-flexboxgrid";

import { getCurrentUserMembership } from "collection/graphql/enterprise/queries";
import { COMPLETED, IRRIGATING, PLANNED } from "lib/constants";
import styles from "modules/activity/common/styles";
import FieldCropFormGroup from "modules/activity/components/ActivityAddEditModal/FieldCropFormGroup";
import NotesField from "modules/activity/components/ActivityAddEditModal/NotesField";
import WaterInput from "modules/activity/components/ActivityAddEditModal/WaterInput";

import { Button } from "components/fl-ui";
import FileAttachments from "components/fl-ui/Attachments/FileAttachmentsAdapter";
import { FormGroup, Select } from "components/fl-ui/Form";
import { CloseX } from "components/fl-ui/Icons";
import { Cluster } from "components/fl-ui/Layout";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "components/fl-ui/Modal/Modal";
import { DatePicker } from "components/fl-ui/common";

const inputTypeLookup = {
  all: "", // just a precaution pending when we remove the corresponding legacy stuff
  FERTILIZING: "fertilizer",
  IRRIGATING: "",
  OTHER: "",
  PLANTING: "seed",
  SPRAYING: "chemical",
  UNKNOWN: "",
};

const statusOptions = [COMPLETED, PLANNED].map((stat) => ({ id: stat, label: _.capitalize(stat), value: stat }));

const AddEditModal = ({
  activity,
  activityType,
  activityTypes,
  bulkCreateAttributes,
  category,
  disabled,
  form,
  handleInputsChange,
  handleSave,
  modalTitle,
  mode,
  model,
  onAbort,
  onDelete,
  onUpdateWaterInput,
  peopleFieldLabel,
  personList,
  primaryButtonText,
  updateFormValues,
  updateState,
}) => {
  const isWeb = !isNative();
  const categoryOptions = _.sortBy(getCategoryOptions(activityTypes, activity?.type?.id), "label");
  const { date, equipment, id, images, inputs, isWorkOrder, irrigation, notes, people, status } = form;

  const { data: userMembership } = useQuery(getCurrentUserMembership);
  const currentUserRole = userMembership?.currentMembership?.role?.name;
  const showNotifyButton = isWorkOrder && ["admin", "manager"].includes(currentUserRole);
  const disableArea = bulkCreateAttributes.length > 1;

  return (
    <Modal width={635}>
      <ModalHeader>
        <ModalTitle>{modalTitle}</ModalTitle>
        <CloseX onClick={onAbort} />
      </ModalHeader>

      <ModalBody>
        <FormGroup
          label={
            <Cluster between>
              <span>Type</span>
              <Link className={css(styles.blueColor)} to="/settings/activities">
                Manage activity types
              </Link>
            </Cluster>
          }
        >
          <ReactSelect
            getOptionLabel={(option) => `${option.name} ${option.isArchived ? "(archived)" : ""}`}
            getOptionValue={({ id }) => id}
            isSearchable={isWeb}
            onChange={(activityType) =>
              updateState({
                activityType,
                form: {
                  ...form,
                  irrigation: activityType.category === IRRIGATING && activity ? activity.irrigation : null,
                  type: activityType.id,
                },
              })
            }
            options={categoryOptions}
            placeholder="Select an activity type..."
            value={activityType}
          />
        </FormGroup>

        <FieldCropFormGroup
          activityType={activityType}
          bulkAttributes={bulkCreateAttributes}
          mode={mode}
          onChange={updateState}
          showClearButton={mode === "create" && bulkCreateAttributes.length > 0}
        />

        {category === IRRIGATING && <WaterInput {...irrigation} onChange={onUpdateWaterInput} />}

        {inputTypeLookup.hasOwnProperty(category) && (
          <FormGroup label="Inputs (optional)">
            <ActivityInputChooser
              disableArea={disableArea}
              inputs={inputs}
              onChange={(inputs) => handleInputsChange(inputs)}
            />
          </FormGroup>
        )}

        <Row>
          <Col xs md={6}>
            <FormGroup label="Status">
              <Select
                name="status"
                onChange={({ target }) => updateFormValues({ status: target.value })}
                options={statusOptions}
                value={status || ""}
              />
            </FormGroup>
          </Col>

          <Col xs md={6}>
            <FormGroup label={status === PLANNED ? "Target date" : "Date completed"}>
              <DatePicker onChange={(date) => updateFormValues({ date })} value={date} />
            </FormGroup>
          </Col>
        </Row>

        <FormGroup label={peopleFieldLabel}>
          <ReactSelect
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            isMulti={!isWorkOrder}
            isSearchable={isWeb}
            onChange={(people) => {
              people = isWorkOrder ? [people] : people;
              updateFormValues({
                people: _.map(people, ({ id }) => id),
              });
            }}
            options={personList}
            styles={chooserStyles}
            value={_.reduce(
              people,
              (selected, id) => {
                const person = _.find(personList, { id });
                if (person) {
                  selected.push(person);
                }

                return selected;
              },
              []
            )}
          />
        </FormGroup>

        <FormGroup label="Equipment & implements (optional)">
          <EquipmentChooser multiple onChange={(equipment) => updateFormValues({ equipment })} value={equipment} />
        </FormGroup>

        <FormGroup label="Notes (optional)">
          <NotesField onChange={(notes) => updateFormValues({ notes })} value={notes} />
        </FormGroup>

        <FormGroup label="Attachments (optional)">
          {_.isArray(images) && (
            <FileAttachments
              carousel
              files={images}
              onChange={(images) => {
                updateFormValues({ images });
                if (model) {
                  model.set({ images });
                }
              }}
            />
          )}
        </FormGroup>
      </ModalBody>
      <ModalFooter className={css(styles.buttonGroup, styles.reverseRow)}>
        <div className={css(styles.buttonGroup)}>
          <Button className={css(styles.rightMargin)} color="primary" link onClick={onAbort}>
            Cancel
          </Button>
          <Button
            className={css(showNotifyButton && styles.rightMargin)}
            color="primary"
            disabled={disabled}
            onClick={() => handleSave(false)}
          >
            {primaryButtonText}
          </Button>
          {showNotifyButton && (
            <Button color="primary" disabled={disabled} onClick={() => handleSave(true)}>
              {mode === "edit" ? "Update & notify" : "Assign & notify"}
            </Button>
          )}
        </div>
        {id && (
          <Button className={css(styles.rightMargin)} color="danger" link onClick={onDelete}>
            Delete activity
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

AddEditModal.propTypes = {
  activity: PropTypes.object,
  bulkCreateAttributes: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
};

export default AddEditModal;
