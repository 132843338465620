import * as Sentry from "@sentry/react";
import _ from "lodash";
import clearFileCache from "mobile/clearFileCache";

import {
  advisorClient,
  forecastClient,
  marketingClient,
  notificationsClient,
  restClient,
  ssoAuthWorkflowClient,
} from "collection/graphql/client";
import storage from "lib/storage";

const clearCaches = async () => {
  const clients = [
    advisorClient,
    forecastClient,
    marketingClient,
    notificationsClient,
    restClient,
    ssoAuthWorkflowClient,
  ];
  const uniqueCaches = _.uniqBy(clients, "cache");

  const actions = [];

  actions.push(storage.clear());
  actions.push(clearFileCache());
  actions.push(...uniqueCaches.map((cache) => cache.clearStore()));

  await Promise.allSettled(actions);
};

const clearData = async () => {
  localStorage.clear();
  sessionStorage.clear();

  Sentry.getCurrentScope().setUser(null);

  await clearCaches();
};

export { clearCaches, clearData };
