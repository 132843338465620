import { useQuery } from "@apollo/client";
import EquipmentDetailSummary from "equipment/components/EquipmentDetailSummary";
import MaintenanceRecordContainer from "equipment/containers/MaintenanceRecordContainer";
import ImplementModal from "equipment/forms/ImplementModal";
import TractorModal from "equipment/forms/TractorModal";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getImplementById, getTractorById } from "collection/graphql/equipment/queries";
import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";
import App from "layout/app";

import PayGateAdBanner from "components/advertisements/banners/PayGateAdBanner";
import { Button } from "components/fl-ui";
import { BreadCrumbHeader, Cluster, Container, Content, ContentMain, ContentSidebar } from "components/fl-ui/Layout";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import RestrictedAccessBlankslate from "components/fl-ui/RestrictedAccessBlankslate";

const DetailPage = ({ type }) => {
  const id = +useParams().id;
  const navigate = useNavigate();
  const isTractor = type === "tractor";
  const [shownModal, setShownModal] = useState(null);
  const [record, setRecord] = useState(null);
  const { loadingAccess, showPaygate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: "equipment",
    permissionName: "equipment",
  });

  const query = isTractor ? getTractorById : getImplementById;
  const { data, error, loading } = useQuery(query, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    variables: { id },
  });
  const equipmentItem = isTractor ? data?.tractor : data?.implement;
  if (error) {
    const message = isTractor ? "The requested tractor was not found." : "The requested implement was not found.";
    App.notify(message);
    navigate("/equipment", { replace: true });
    return null;
  } else if (loading && !equipmentItem) {
    return <LoadingWrapper isLoading />;
  }

  const breadcrumbs = [{ enabled: true, label: "Equipment", link: "/equipment" }];
  if (equipmentItem) {
    breadcrumbs.push({ label: equipmentItem.name });
  }

  const handleEdit = () => setShownModal(isTractor ? "tractor" : "implement");

  return (
    <Container>
      <LoadingWrapper isLoading={loadingAccess}>
        <BreadCrumbHeader links={breadcrumbs} />

        {showPaygate && <PayGateAdBanner />}
        {showRestricted && <RestrictedAccessBlankslate />}
        {showContent && (
          <Content>
            <ContentSidebar>
              <EquipmentDetailSummary equipment={equipmentItem} onEdit={handleEdit} type={type} />
            </ContentSidebar>

            <ContentMain>
              <Cluster between>
                <h2>Maintenance</h2>
                <Button onClick={() => setRecord({ equipment: equipmentItem })}>Log maintenance</Button>
              </Cluster>

              <MaintenanceRecordContainer equipment={equipmentItem} record={record} setRecord={setRecord} />
            </ContentMain>
          </Content>
        )}
      </LoadingWrapper>

      {shownModal === "implement" && (
        <ImplementModal
          implement={equipmentItem}
          onClose={() => setShownModal(null)}
          onDelete={() => {
            navigate("/equipment", { replace: true });
          }}
          onSaveSuccess={() => {
            setShownModal(null);
          }}
        />
      )}

      {shownModal === "tractor" && (
        <TractorModal
          onClose={() => setShownModal(null)}
          onDelete={() => {
            navigate("/equipment", { replace: true });
          }}
          onSaveSuccess={() => {
            setShownModal(null);
          }}
          tractor={equipmentItem}
        />
      )}
    </Container>
  );
};

DetailPage.screenName = "Equipment Detail";
DetailPage.title = "Equipment Detail";

DetailPage.propTypes = {
  type: PropTypes.oneOf(["implement", "tractor"]).isRequired,
};

export default DetailPage;
