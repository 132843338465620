import styles from "marketing/cards/cardStyles";
import formatCurrency from "marketing/cards/utils/formatCurrency";
import Cell, { CellRow } from "marketing/components/ui-elements/Cell";
import PropTypes from "prop-types";
import React from "react";

import { IconContainer } from "components/fl-ui/Icons";
import ToolTip from "components/fl-ui/Tooltip";
import { css } from "components/fl-ui/aphrodite";
import CommodityYieldUnit from "components/units/CommodityYieldUnit";

const LegacyCropCardDetails = ({ marketedCrop }) => {
  const isExchangeTraded = marketedCrop?.commodity?.isExchangeTraded;
  const Unit = () => <CommodityYieldUnit commodity={marketedCrop?.commodity} defaultUnit="bu" />;

  return (
    <>
      <CellRow className={css(styles.dataRow, styles.borderTop)}>
        <Cell>
          Your Avg Sold Price
          <ToolTip message="Price represents the futures reference component only. Basis is established separately.">
            <IconContainer icon="help" size="regular" />
          </ToolTip>
        </Cell>
        <Cell right>
          {formatCurrency(marketedCrop?.progress?.sold?.valuePerUnit)} <Unit />
        </Cell>
      </CellRow>

      <CellRow className={css(styles.dataRow, styles.noBorderBottom)}>
        <Cell>{isExchangeTraded ? "Market Price" : "Budget Price"}</Cell>
        <Cell right>
          {formatCurrency(isExchangeTraded ? marketedCrop?.currentMarketPrice : marketedCrop?.harvestPrice)} <Unit />
        </Cell>
      </CellRow>
    </>
  );
};

LegacyCropCardDetails.propTypes = {
  marketedCrop: PropTypes.object.isRequired,
};

export { LegacyCropCardDetails };
