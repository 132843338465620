import { StyleSheet } from "aphrodite/no-important";

import { GreyColors, UIColors, ColorPalette } from "components/fl-ui/colors";
import { Borders, Mixins, Spacing, Typography, BorderRadius } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  // Promo Banner
  // layout
  centered: {
    textAlign: "center",
    marginLeft: "auto !important",
    marginRight: "auto !important",
  },
  centered_on_mobile: {
    "@media only screen and (max-width: 48.75em)": {
      textAlign: "center",
      marginLeft: "auto !important",
      marginRight: "auto !important",
    },
  },
  centered_on_tablet: {
    "@media only screen and (max-width: 62.5em)": {
      textAlign: "center",
      marginLeft: "auto !important",
      marginRight: "auto !important",
    },
  },
  banner_container: {
    position: "relative",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundColor: "#fdfdfd",
    backgroundImage: "url(images/pay_gates/lines.png)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    padding: Spacing.large,
    borderRadius: "4px",
    maxHeight: "48em",
    "@media only screen and (max-width: 48.75em)": {
      maxHeight: "none",
    },
  },
  banner_container_fullbleed: {
    position: "relative",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundColor: "#fdfdfd",
    backgroundImage: "url(images/pay_gates/banners/farm.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 50%",
    backgroundSize: "contain",
    padding: Spacing.large,
    borderRadius: "4px",
    maxHeight: "48em",
    marginTop: Spacing.large,
    marginBottom: Spacing.large,
    "@media only screen and (max-width: 48.75em)": {
      backgroundSize: "0",
    },
  },
  banner_container_fullbleed_legacy: {
    position: "relative",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundColor: "#fdfdfd",
    backgroundImage: "url(images/pay_gates/banners/greenFields.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 50%",
    backgroundSize: "contain",
    padding: Spacing.large,
    borderRadius: "4px",
    maxHeight: "48em",
    marginTop: Spacing.large,
    marginBottom: Spacing.large,
    "@media only screen and (max-width: 48.75em)": {
      backgroundSize: "0",
    },
  },
  banner_content_item: {
    lineHeight: "1.5",
  },
  banner_content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "@media only screen and (max-width: 48.75em)": {
      flexDirection: "column-reverse",
      justifyContent: "flex-start",
    },
  },
  banner_footer: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  banner_textContainer: {
    width: "50%",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: Spacing.large,
    paddingBottom: Spacing.large,
    paddingRight: Spacing.large,
    paddingLeft: 0,
    "@media only screen and (max-width: 48.75em)": {
      width: "100%",
      paddingTop: 0,
      paddingBottom: Spacing.large,
      paddingRight: 0,
      justifyContent: "flex-start",
    },
  },
  secondaryText: {
    fontSize: "1em",
    lineHeight: "1.5",
  },
  valueProp: {
    marginLeft: Spacing.large,
    marginBottom: Spacing.small,
    color: "#17181C",
    listStyle: "disc",
    lineHeight: "1.5",
  },
  banner_textContainer_sm: {
    paddingTop: Mixins.toRem(30),
    width: "60%",
    "@media only screen and (max-width: 48.75em)": {
      width: "100%",
    },
  },
  banner_textContainer_largeImg: {
    width: "45%",
    "@media only screen and (max-width: 48.75em)": {
      width: "100%",
    },
  },
  banner_imgContainer: {
    overflowY: "auto",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "50%",
    maxHeight: "40em",
    paddingTop: Spacing.large,
    paddingBottom: Spacing.large,
    "@media only screen and (min-width: 320px) and (max-width: 667px)": {
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "center",
      paddingTop: Spacing.large,
      maxHeight: "12em",
      marginBottom: Spacing.large,
    },
    "@media only screen and (max-width: 62.5em)": {
      width: "100%",
      maxHeight: "20em",
      marginBottom: Spacing.large,
    },
  },
  banner_imgContainer_sm: {
    width: "40%",
    paddingTop: Mixins.toRem(30),
    "@media only screen and (max-width: 48.75em)": {
      width: "100%",
    },
  },
  banner_imgContainer_largeImg: {
    width: "55%",
    "@media only screen and (max-width: 48.75em)": {
      width: "100%",
    },
  },
  banner_img: {
    display: "grid",
    width: "auto",
    minWidth: "100%",
    "@media only screen and (max-width: 48.75em)": {
      maxWidth: "100%",
      height: "auto",
    },
  },

  icon_feature: {
    display: "flex",
    alignItems: "center",
    marginBottom: Spacing.regular,
  },
  icon_feature_h5: {
    margin: 0,
    fontSize: Mixins.toRem(11),
    lineHeight: 1.818,
    fontWeight: Typography.weights.medium,
    textTransform: "uppercase",
  },
  icon_feature_p: {
    lineHeight: 1.42,
    margin: 0,
    fontWeight: Typography.weights.regular,
    maxWidth: Mixins.toRem(400),
  },
  icon_round: {
    padding: Mixins.toRem(10),
    border: Borders.regular,
    borderRadius: "50%",
    height: Mixins.toRem(48),
    width: Mixins.toRem(48),
    marginRight: Spacing.regular,
  },
  icon: {
    color: UIColors.blue,
    height: Mixins.toRem(24),
    width: Mixins.toRem(24),
    marginLeft: "auto",
    marginRight: "auto",
  },
  promo_centeredContent: {
    textAlign: "center",
    maxWidth: Mixins.toRem(500),
    marginLeft: "auto !important",
    marginRight: "auto !important",
  },
  promo_cta: {
    display: "block !important",
    marginBottom: `${Spacing.regular} !important`,
    paddingTop: `${Spacing.minor} !important`,
    paddingBottom: `${Spacing.minor} !important`,
    width: "100% !important",
    maxWidth: `${Mixins.toRem(346)} !important`,
  },
  promo_h2: {
    fontSize: Typography.sizes.xxxxl,
    fontWeight: Typography.weights.regular,
    lineHeight: 1.25,
    color: "#17181C",
    marginTop: 0,
    marginBottom: Spacing.small,
  },
  emphasized: {
    color: UIColors.green,
  },
  promo_h4: {
    fontSize: Typography.sizes.lg,
    lineHeight: 1.375,
    color: "#17181C",
    marginTop: 0,
    marginBottom: Spacing.small,
  },
  promo_text: {
    lineHeight: 1.5,
    marginBottom: Spacing.large,
    fontWeight: Typography.weights.regular,
    color: "#17181C",
  },
  promo_text_light: {
    fontWeight: Typography.weights.regular,
  },
  promo_hr: {
    marginTop: Spacing.large,
    marginBottom: Spacing.large,
  },
  promo_hr_small: {
    width: Mixins.toRem(55),
  },

  // modals
  modal_body: {
    borderRadius: BorderRadius.small,
    backgroundColor: "#fdfdfd",
    backgroundImage: "url(images/pay_gates/lines.png)",
  },
  modal_closeX: {
    position: "absolute",
    right: Mixins.toRem(18),
    top: Mixins.toRem(18),
  },

  // utils
  marginTop_24: {
    marginTop: `${Spacing.large} !important`,
  },
  marginBottom_24: {
    marginBottom: `${Spacing.large} !important`,
  },
  marginBottom_0: {
    marginBottom: `0 !important`,
  },
  maxWidth_500: {
    maxWidth: Mixins.toRem(500),
  },

  // numbered headers
  promo_numHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: Spacing.minor,
  },
  promo_numHeader_hr: {
    height: "1px",
    border: `1px solid ${GreyColors.smoke90}`,
    flexGrow: 2,
  },
  promo_numHeader_number: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: Spacing.xxlarge,
    height: Spacing.xxlarge,
    marginRight: Spacing.minor,
    borderRadius: "50%",
    border: `2px solid ${GreyColors.smoke90}`,
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.medium,
    lineHeight: 1,
  },

  // how it works
  steps_col: {
    marginBottom: Spacing.xxlarge,
  },
  steps_card: {
    height: "100%",
  },
  steps_number: {
    fontSize: Mixins.toRem(40),
    lineHeight: 1,
    marginTop: Spacing.large,
    marginBottom: Spacing.large,
  },
  steps_color: {
    display: "inline-block",
    paddingRight: Spacing.large,
    paddingBottom: Spacing.large,
    borderBottom: `3px solid ${UIColors.medium}`,
  },
  steps_color_green: {
    borderColor: UIColors.green,
  },
  steps_color_red: {
    borderColor: UIColors.red,
  },
  steps_color_yellow: {
    borderColor: ColorPalette["dark-yellow"],
  },
  steps_text: {
    fontSize: Mixins.toRem(18),
    lineHeight: 1.5,
  },

  // page footer
  footer_h3: {
    fontSize: Typography.sizes.xxl,
    lineHeight: 1.29,
    fontWeight: Typography.weights.medium,
  },
});

export default styles;
