import React from "react";
import styled from "styled-components";

import { IconContainer } from "components/fl-ui/Icons";
import ToolTip from "components/fl-ui/Tooltip";
import { ColorPalette } from "components/fl-ui/colors";

const TitleArea = styled(({ className, hasContracts }) => (
  <h3 className={className}>
    CURRENT POSITION
    {hasContracts && (
      <ToolTip message="Assign contracts to this marketing crop to update your current position.">
        <IconContainer icon="help" size="regular" />
      </ToolTip>
    )}
  </h3>
))`
  svg {
    color: ${ColorPalette["blue"]};
  }
`;

export { TitleArea };
